import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/AIJourneySettingsPageQuery.graphql';

export const AIJourneySettingsPageEntryPoint = createEntryPoint({
  component: () => import('./AIJourneySettingsPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: { parameters: Query, variables: { companyId: getCurrentCompanyId() } },
  }),
});
