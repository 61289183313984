import React, { useCallback } from 'react';
import { useRefetchableFragment } from 'react-relay';

import { PicnicCss, Heading, Link, Text } from '@attentive/picnic';
import {
  Config,
  useCompanyFeatureFlag,
  useCurrentCompanyId,
  usePermission,
  useRoles,
} from '@attentive/acore-utils';
import { Permission, Role } from '@attentive/data';
import { useAiProReportingStates } from '@attentive/reporting-platform';
import { useHasAIJourneysAccess } from '@attentive/ml-ui/src/aiJourneys';

import { Routes } from '../../../../../routes';
import { useAppBodyQueryData } from '../../../../authenticated-app/AppBody';
import { useIntegrationErrorsCount } from '../../../../../utils/hooks';
import { useRefreshTimer } from '../../../../clocks/useRefreshTimer';

import ClientUIUnreadConversationsCountFragment, {
  ClientUIUnreadConversationsCountFragment_company$key,
} from '../__generated__/ClientUIUnreadConversationsCountFragment_company.graphql';
import { ClientUIUnreadConversationsCountFragmentRefetchQuery } from '../__generated__/ClientUIUnreadConversationsCountFragmentRefetchQuery.graphql';

import { SidebarNavContainer } from './SidebarNavContainer';
import { SidebarNavItem, SidebarNavOnClickHandler } from './SidebarNavItem';
import { SidebarSubNavItem } from './SidebarSubNavItem';

const MAX_UNREAD_COUNT = 99;
const REFRESH_INTERVAL_MS = 60 * 1000;
const referenceUiVisible = Config.get('referenceUiVisibleInSidebar');

const formatUnreadConversationsCount = (count?: number | null) => {
  if (!count) {
    return null;
  }

  if (count > MAX_UNREAD_COUNT) {
    return '99+';
  }
  return String(count);
};

const formatIntegrationErrorsCount = (count: number) => {
  return count === 0 ? null : String(count);
};

interface SidebarNavProps {
  css?: PicnicCss;
  onClickItem?: SidebarNavOnClickHandler;
}

const SectionHeading: React.FC = ({ children }) => {
  return (
    <Heading
      variant="subheading"
      css={{
        pt: '$space4',
        pb: '$space1',
        px: '$space5',
      }}
    >
      {children}
    </Heading>
  );
};

export const SidebarNav: React.FC<SidebarNavProps> = ({ css, onClickItem }) => {
  const queryData = useAppBodyQueryData();
  const isSuperUser = usePermission(Permission.SuperUserAccess);
  const currentCompanyId = useCurrentCompanyId();
  const roles = useRoles();
  const lowDataMode = useCompanyFeatureFlag('UI_LOW_DATA_MODE');
  const conversationsRouteEnabled =
    useCompanyFeatureFlag('CONVERSATIONS') && !roles.has(Role.RoleClientLimitedAccount);
  const integrationsRouteEnabled = useCompanyFeatureFlag('UI_INTEGRATIONS');
  const integrationsErrorsEnabled = useCompanyFeatureFlag('ENABLE_INTEGRATION_ERRORS');
  const isConciergeEnabled = useCompanyFeatureFlag('ENABLE_JOURNEYS_CONCIERGE');
  const isConversationsSubNavEnabled = useCompanyFeatureFlag('ENABLE_CONVERSATIONS_SUBNAV');
  const isConciergeOnBoardingEnabled = useCompanyFeatureFlag('ENABLE_CONCIERGE_ONBOARDING');
  const isBrandKitEnabled = useCompanyFeatureFlag('ENABLE_BRAND_KIT');
  const isEmailEnabled = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const isAIJourneysPerformanceEnabled = useCompanyFeatureFlag('ENABLE_AI_JOURNEYS_PERFORMANCE_UI');

  const { showAiProDashboard } = useAiProReportingStates(queryData);
  const [hasAIJourneysAccess] = useHasAIJourneysAccess(queryData);
  const showJourneysSubNav = isAIJourneysPerformanceEnabled && hasAIJourneysAccess;

  const [countFragData, refetch] = useRefetchableFragment<
    ClientUIUnreadConversationsCountFragmentRefetchQuery,
    ClientUIUnreadConversationsCountFragment_company$key
  >(ClientUIUnreadConversationsCountFragment, queryData.company);
  const refreshUnreadConversationsCount = useCallback(async () => {
    if (lowDataMode || !conversationsRouteEnabled) {
      return;
    }

    refetch({}, { fetchPolicy: 'store-and-network' });
  }, [lowDataMode, conversationsRouteEnabled, refetch]);
  useRefreshTimer(refreshUnreadConversationsCount, REFRESH_INTERVAL_MS);

  const integrationErrorsCount = useIntegrationErrorsCount({
    companyId: currentCompanyId,
    isIntegrationErrorsEnabled: integrationsErrorsEnabled,
  });

  return (
    <SidebarNavContainer css={css}>
      <SidebarNavItem path={Routes.Home} label="Home" iconName="Home" onClick={onClickItem} />
      <SectionHeading>Growth</SectionHeading>
      <SidebarNavItem
        path={Routes.SignUpUnits}
        label="Sign-up Units"
        iconName="Rocket"
        onClick={onClickItem}
      />

      <SectionHeading>Audience</SectionHeading>
      <SidebarNavItem
        path={Routes.Subscribers}
        label="Subscribers"
        iconName="PersonStacked"
        onClick={onClickItem}
      />
      <SidebarNavItem
        path={Routes.Segments}
        label="Segments"
        iconName="Segments"
        onClick={onClickItem}
      />

      <SectionHeading>Messaging</SectionHeading>
      <SidebarNavItem
        path={Routes.Journeys}
        label="Journeys"
        iconName="Workflow"
        onClick={onClickItem}
        trackingId={showJourneysSubNav ? 'nav-journeys-expand' : undefined}
      >
        {showJourneysSubNav && [
          <SidebarSubNavItem
            key="JourneysAI"
            label="AI Journeys"
            path={Routes.JourneysAI}
            onClick={onClickItem}
            trackingId="nav-ai-journeys"
          />,
          <SidebarSubNavItem
            key="JourneysStandard"
            label="Standard Journeys"
            path={Routes.JourneysStandard}
            onClick={onClickItem}
            trackingId="nav-standard-journeys"
          />,
        ]}
      </SidebarNavItem>
      <SidebarNavItem
        path={Routes.Campaigns}
        label="Campaigns"
        iconName="Megaphone"
        onClick={onClickItem}
      />
      {conversationsRouteEnabled && (
        <SidebarNavItem
          path={Routes.Conversations}
          label="Conversations"
          iconName="MessageBubbleDots"
          badgeContent={formatUnreadConversationsCount(
            countFragData?.clientConversationUnreadCount?.clientConversationUnreadCount
          )}
          badgeAriaLabel="Unread Conversations Count"
          onClick={onClickItem}
        >
          {(isConciergeEnabled || isConciergeOnBoardingEnabled) &&
            isConversationsSubNavEnabled && [
              <SidebarSubNavItem
                key="ConversationsOverview"
                label="Concierge"
                path={Routes.ConversationsConcierge}
                onClick={onClickItem}
              />,
              <SidebarSubNavItem
                key="ConversationsInbox"
                label="Inbox"
                path={Routes.ConversationsInbox}
                onClick={onClickItem}
              />,
            ]}
        </SidebarNavItem>
      )}

      <SectionHeading>Analytics</SectionHeading>
      <SidebarNavItem
        path={Routes.AnalyticsDashboard}
        label="Dashboard"
        iconName="PieChart"
        onClick={onClickItem}
      >
        {showAiProDashboard && [
          <SidebarSubNavItem
            key="DashboardOverview"
            label="Overview"
            path={Routes.AnalyticsDashboard}
            onClick={onClickItem}
          />,
          <SidebarSubNavItem
            key="DashboardAiPro"
            label="AI Pro"
            path={Routes.AnalyticsAiPro}
            onClick={onClickItem}
          />,
        ]}
      </SidebarNavItem>
      <SidebarNavItem
        label="Reports"
        path={Routes.AnalyticsReports}
        iconName="BarChart"
        onClick={onClickItem}
      />

      <SectionHeading>Setup</SectionHeading>
      {integrationsRouteEnabled && (
        <SidebarNavItem
          path={Routes.Integrations}
          label="Marketplace"
          iconName="SquaresIntersecting"
          badgeContent={formatIntegrationErrorsCount(integrationErrorsCount)}
          badgeVariant="error"
          badgeAriaLabel="Integration Errors Count"
          onClick={onClickItem}
        />
      )}
      <SidebarNavItem path={Routes.Offers} label="Offers" iconName="Coupon" onClick={onClickItem} />

      {isBrandKitEnabled && (
        <SidebarNavItem
          path={Routes.BrandKit}
          label="Brand Kit"
          iconName="BrandKit"
          onClick={onClickItem}
          featureAnnouncement={{
            contentId: 'brand-kit-launch',
            title: 'A new home for all of your brand assets!',
            description: (
              <Text>
                Upload your brand's colors, fonts, and logo one time for automatic application to
                all sign-up units.{' '}
                <Link
                  href="https://help.attentivemobile.com/hc/en-us/articles/4402991132180--Beta-Set-up-your-Brand-Kit"
                  target="_blank"
                >
                  Learn more
                </Link>{' '}
                about brand kit.
              </Text>
            ),
          }}
        />
      )}
      {isEmailEnabled && (
        <SidebarNavItem
          path={Routes.Templates}
          label="Email Templates"
          iconName="Template"
          onClick={onClickItem}
        />
      )}

      {isSuperUser && (
        <SidebarNavItem
          path={Routes.Tag}
          label="Tag"
          iconName="ShieldPerson"
          onClick={onClickItem}
        />
      )}
      <SidebarNavItem
        path={Routes.Settings}
        label="Settings"
        iconName="Cog"
        onClick={onClickItem}
        featureAnnouncement={{
          contentId: 'user-access-mngmt-launch',
          title: 'Manage your team!',
          description: (
            <Text>
              Easily create, add, and manage users for your Attentive account.{' '}
              <Link
                href="https://help.attentivemobile.com/hc/en-us/articles/7919103329556--Beta-Manage-your-team"
                target="_blank"
              >
                Learn more
              </Link>{' '}
              about managing user access in the help center.
            </Text>
          ),
        }}
      />
      {referenceUiVisible && (
        <SidebarNavItem
          path={Routes.Reference}
          label="Reference UI"
          iconName="EyeStrikethrough"
          onClick={onClickItem}
        />
      )}
    </SidebarNavContainer>
  );
};
