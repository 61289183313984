import { createEntryPoint } from '@attentive/data-router';

import Query from './__generated__/SyncConfigurationDialog_EDSLib_Query.graphql';

export const SyncConfigurationDialogEntryPoint = createEntryPoint({
  component: () => import('./SyncConfigurationDialog'),
  getQueries: ({ getCurrentCompanyId }) => {
    return { query: { parameters: Query, variables: { companyId: getCurrentCompanyId() } } };
  },
});
