import React from 'react';

import { Box, BoxProps, DropdownMenu, Heading, IconButton, Link, Text } from '@attentive/picnic';

import { AdminMenu } from '../AdminMenu';
import { EmergencyAdminMenu } from '../EmergencyAdminMenu';
import { AccountName } from '../../../../../common/account-name';
import { CompanyName } from '../../../../../common/company-name';
import { RecentCompaniesMenuSection } from '../RecentCompaniesMenuSection';

import { AccountMenuVariant } from '../index';
import { AccountMenuClientUIFragment_query$data } from '../__generated__/AccountMenuClientUIFragment_query.graphql';

import { useRoles } from '@attentive/acore-utils';
import { Role } from '@attentive/data';

const PU_STITCHES_BUG_TEXT_DECORATION_OVERRIDE = 'none !important';

export interface AccountDropdownMenuProps extends BoxProps {
  variant?: AccountMenuVariant;
  fragData: AccountMenuClientUIFragment_query$data;
  hasAdminUIAccess: boolean;
  inEmergencyMode: boolean;
  signOut: () => void;
  setShowSwitchCompanyDialog: (open: boolean) => void;
}

export const AccountDropdownMenu: React.VFC<AccountDropdownMenuProps> = ({
  css,
  variant = AccountMenuVariant.FULL_TEXT,
  fragData,
  hasAdminUIAccess,
  inEmergencyMode,
  signOut,
  setShowSwitchCompanyDialog,
}) => {
  const accountRef = fragData.viewer.account;
  const companyRef = fragData.company;
  const roles = useRoles();
  const showReferAFriend = !roles.has(Role.RoleClientLimitedAccount);

  return (
    <DropdownMenu>
      {variant === AccountMenuVariant.ICON_DROPDOWN && (
        <DropdownMenu.Trigger>
          <IconButton
            description="Account dropdown menu"
            css={{
              textDecoration: PU_STITCHES_BUG_TEXT_DECORATION_OVERRIDE,
              ...css,
            }}
            iconName="Person"
          />
        </DropdownMenu.Trigger>
      )}
      {variant === AccountMenuVariant.FULL_TEXT && (
        <DropdownMenu.Trigger>
          <DropdownMenu.Button
            css={{
              textDecoration: PU_STITCHES_BUG_TEXT_DECORATION_OVERRIDE,
              fontWeight: '$regular',
              ...css,
            }}
          >
            <CompanyName companyRef={companyRef} />
          </DropdownMenu.Button>
        </DropdownMenu.Trigger>
      )}
      <DropdownMenu.Content align="end">
        <DropdownMenu.Label>
          <div>
            <Heading as="h4" variant="sm">
              <AccountName accountRef={accountRef} />
            </Heading>
            <Text variant="caption">
              <CompanyName companyRef={companyRef} />
            </Text>
          </div>
        </DropdownMenu.Label>
        <DropdownMenu.Separator />
        {hasAdminUIAccess && (
          <>
            {inEmergencyMode ? <EmergencyAdminMenu /> : <AdminMenu />}
            <DropdownMenu.Separator />
          </>
        )}
        <RecentCompaniesMenuSection
          queryRef={fragData}
          onMoreCompaniesClick={() => {
            setShowSwitchCompanyDialog(true);
          }}
          variant={variant}
        />
        {showReferAFriend && (
          <>
            <DropdownMenu.TextItem asChild>
              <a
                href="https://info.attentivemobile.com/attentive-advocates-customer-referral/?utm_source=customer-mktg-referral-promo&utm_medium=attn-ui&utm_campaign=referral-promo-attn-ui"
                target="_blank"
                rel="noopener noreferrer"
                data-nav-id="refer-a-friend"
              >
                Refer a Friend
              </a>
            </DropdownMenu.TextItem>

            <DropdownMenu.Separator />
          </>
        )}
        <DropdownMenu.TextItem onSelect={signOut}>Sign out</DropdownMenu.TextItem>
        <DropdownMenu.Separator />
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            padding: '$space2 $space3',
          }}
        >
          <DropdownMenu.UnstyledItem asChild>
            <Link
              href="https://attentivemobile.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text variant="micro">Terms</Text>
            </Link>
          </DropdownMenu.UnstyledItem>
          <Text variant="micro">&nbsp;and&nbsp;</Text>
          <DropdownMenu.UnstyledItem asChild>
            <Link
              href="https://attentivemobile.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text variant="micro">Privacy</Text>
            </Link>
          </DropdownMenu.UnstyledItem>
        </Box>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};
